import { Theme, TypographyProps } from "@mui/material";
import { SystemStyleObject } from "@mui/system";

export const getBoxStyles = (isEditable: boolean) =>
  !isEditable
    ? {
        cursor: "pointer",
        width: "100%",
        borderBottom: "0.15rem solid var(--primary-color-100)",
        "&:hover": {
          borderBottom: "0.15rem solid var(--primary-color-900)",
          transform: "scale(1.02)",
          transition: "transform 0.2s ease-in-out",
        },
      }
    : {};

export const getNumberInputStyles = (
  theme: Theme, // MUI Theme object
  typographyProps?: TypographyProps // Optional TypographyProps
): SystemStyleObject<Theme> => {
  // Extract the variant, fontSize, and fontWeight from typographyProps
  const { variant = "body1", fontSize, fontWeight } = typographyProps ?? {};

  // Create a type guard to check if the variant is a valid key in theme.typography
  const isValidVariant = (key: string): key is keyof typeof theme.typography => {
    return key in theme.typography;
  };

  // Determine the styles based on typographyProps or fallbacks from the theme
  const calculatedFontSize =
    fontSize ??
    (isValidVariant(variant)
      ? theme.typography[variant].fontSize
      : theme.typography.body1.fontSize);
  const calculatedFontWeight =
    fontWeight ??
    (isValidVariant(variant)
      ? theme.typography[variant].fontWeight
      : theme.typography.body1.fontWeight);

  return {
    "& .MuiInputBase-input": {
      textAlign: "right",
      fontSize: calculatedFontSize as string | number, // Ensure fontSize is a string or number
      fontWeight: calculatedFontWeight as string | number, // Ensure fontWeight is a string or number
    },
    "& .MuiInput-underline:before": {
      borderBottomWidth: "0.15rem",
    },
    "& .MuiInput-underline:after": {
      borderBottomWidth: "0.15rem",
    },
  };
};
