import {
  DigitRange,
  MID_AMOUNT_RANGE,
  EMISSION_RANGE,
  DURATION_RANGE,
  PERCENTAGE_RANGE,
  LARGE_AMOUNT_RANGE,
  X_LARGE_AMOUNT_RANGE,
} from "@/shared/constants";
import { getCurrencySymbol } from "@/shared/utils";

type UnitSymbol = {
  prefix: string | undefined;
  suffix: string | undefined;
};

export const getUnit = (category: string, customUnit?: string): UnitSymbol => {
  const decoratorSymbol: UnitSymbol = { prefix: undefined, suffix: undefined };

  switch (category) {
    case "currency":
      decoratorSymbol.prefix = getCurrencySymbol();
      break;
    case "emissions":
      decoratorSymbol.suffix = " tCO₂e ";
      break;
    case "duration":
      decoratorSymbol.suffix = " days ";
      break;
    case "percentage":
      decoratorSymbol.suffix = "%";
      break;
    case "outcome":
      decoratorSymbol.suffix = customUnit ? ` ${customUnit}` : undefined;
      break;
    default:
      break;
  }
  return decoratorSymbol; // Return the decoratorSymbol object
};

/**
 * Returns the appropriate range based on the provided category.
 * @param category - The category used to determine the range.
 * @returns The corresponding DigitRange object.
 */
export const getRangeByCategory = (category: string | undefined): DigitRange => {
  switch (category) {
    case "currency":
      return MID_AMOUNT_RANGE;
    case "emissions":
      return EMISSION_RANGE;
    case "duration":
      return DURATION_RANGE;
    case "percentage":
      return PERCENTAGE_RANGE;
    case "outcome" || "quantity":
      return LARGE_AMOUNT_RANGE;
    default:
      return X_LARGE_AMOUNT_RANGE;
  }
};
