/* eslint-disable @typescript-eslint/no-unsafe-assignment -- This is how it is used in Mui documentation */
/* eslint-disable @typescript-eslint/no-explicit-any -- This is how it is used in Mui documentation */

import { TextField, TypographyProps, useTheme } from "@mui/material";
import { useState } from "react";

import { getRangeByCategory } from "../helpers/numberFormatHelpers";
import { getNumberInputStyles } from "../styles/styles";

import NumberInputFormatter from "./NumberInputFormatter";

type RNumberProps = {
  value: number;
  onInputChange: (value: number) => void;
  range?: { min: number; max: number };
  allowNegative?: boolean;
  decimalScale?: number;
  typographyProps?: TypographyProps;
  category?:
    | "currency"
    | "emissions"
    | "duration"
    | "percentage"
    | "quantity"
    | "outcome";
};

export default function RNumberInput({
  value,
  allowNegative = false,
  decimalScale = 2,
  range,
  onInputChange,
  typographyProps,
  category = undefined,
}: RNumberProps) {
  const theme = useTheme();
  const validRange = range ? range : getRangeByCategory(category);
  const [inputValue, setValues] = useState<number>(value);

  const handleBlur = () => {
    if (inputValue !== value) {
      onInputChange(inputValue);
    }
  };

  const handleKeyPress = (e: React.KeyboardEvent) => {
    if (e.key === "Enter" && inputValue !== value) {
      onInputChange(inputValue);
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseFloat(event.target.value);
    setValues(value);
  };

  // Helper function to handle focus and select the text
  const handleFocusAndSelect = (input: HTMLInputElement | null) => {
    if (input) {
      input.focus();
      input.select();
    }
  };

  return (
    <TextField
      variant="standard"
      size="medium"
      value={inputValue}
      onChange={handleChange}
      onBlur={handleBlur}
      onKeyDown={handleKeyPress}
      inputRef={handleFocusAndSelect}
      fullWidth
      sx={getNumberInputStyles(theme, typographyProps)}
      slotProps={{
        input: {
          inputComponent: NumberInputFormatter as any,
          inputProps: {
            allowNegative,
            decimalScale,
            validRange,
          },
        },
      }}
    />
  );
}
