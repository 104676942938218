import Box from "@mui/material/Box";
import { MRT_Cell, MaterialReactTable } from "material-react-table";
import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";

import updateOrCreateParameter from "@/features/parametric/api/editParameter";
import {
  initializingParametricTable,
  getParameterToUpdateByName,
  updateParameterColumn,
  sortParameters,
} from "@/features/parametric/helpers/helper";
import {
  BaselineOverviewSchema,
  updateBaselineOverviewSchema,
  BaselineOverview,
} from "@/shared/components/baselineOverview";
import useProjectWBSContext from "@/shared/context/projectWBS/useProjectWBSContext";
import ParametricCostTypes from "@/shared/enums/parametricCostTypes";
import { ParameterModels } from "@/shared/types/parameterModels";

import ColumnsForParametricTable from "../columns/ColumnsForParametricTable";

type ParametricModelTableProp = {
  parameters: ParameterModels[];
  includeOnlyOutcome?: boolean | undefined;
};

export default function ParametricModelTable({
  parameters,
  includeOnlyOutcome = false,
}: ParametricModelTableProp) {
  // Hooks:
  const { outcome: wbsEntryId } = useParams();
  const { refetchProjectWBS, getWBSEntryById, isLoading } = useProjectWBSContext();
  const WBSEntry = getWBSEntryById(wbsEntryId);
  const parameterTableData = useMemo(
    () => initializingParametricTable(parameters, wbsEntryId, includeOnlyOutcome),
    [parameters, wbsEntryId, includeOnlyOutcome]
  );
  // States:
  const [parametersData, setParametersData] =
    useState<ParameterModels[]>(parameterTableData);

  useEffect(() => {
    setParametersData(
      initializingParametricTable(parameters, wbsEntryId, includeOnlyOutcome)
    );
  }, [parameters, wbsEntryId, includeOnlyOutcome]);
  // Updates a parameter in the table by sending the entire object with the new value to the backend
  const handleSaveCell = async (
    cell: MRT_Cell<ParameterModels>,
    newCellValue: number | string
  ) => {
    // Find the parameter that was updated
    const parameterToUpdate = getParameterToUpdateByName(
      parametersData,
      cell.row.original.name
    );

    // Update the specific column with the new value
    const updatedParameter = updateParameterColumn(
      parameterToUpdate,
      cell.column.id,
      newCellValue
    );

    try {
      const updatedParameterFromAPI = await updateOrCreateParameter(updatedParameter);
      // Update the state with the new parameter
      setParametersData((prevParameters) =>
        prevParameters.map((param) => {
          // Using name instead of id to compare because if is a new instance param.id wont exist
          if (param.name === updatedParameterFromAPI.name) {
            return updatedParameterFromAPI;
          }
          return param;
        })
      );
    } catch (error) {
      // we need to deal with error
    }
    refetchProjectWBS();
  };

  const parametricOutcome = parametersData.find(
    //eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison -- we need to add a type to the parameter to compare enum with enum rather than name with enum. Must be fixed when refactoring
    (item) => item.name === ParametricCostTypes.Outcome
  );
  const OverviewItems = updateBaselineOverviewSchema(
    BaselineOverviewSchema,
    WBSEntry,
    parametricOutcome
  );
  const baselineOverviewTitle = WBSEntry
    ? `Level ${WBSEntry.level} — ${WBSEntry.name}`
    : "Error: Title was unable to load";

  const columns = ColumnsForParametricTable({ parameters, handleSaveCell });
  return (
    <>
      <BaselineOverview
        OverviewItems={OverviewItems}
        isLoading={isLoading}
        title={baselineOverviewTitle}
      />

      <Box sx={{ marginTop: "24px" }}>
        <MaterialReactTable
          data={sortParameters(parametersData)}
          columns={columns}
          initialState={{ density: "compact" }}
          // Header styles
          positionToolbarAlertBanner="none"
          muiTableHeadCellProps={{
            sx: () => ({
              background: "var(--primary-color)",
              color: "#fff",
              fontSize: "12px",
            }),
          }}
          muiTableBodyCellProps={{
            sx: {
              border: "none",
              background: "#fff",
              outline: "none",
              boxShadow: "none",
            },
          }}
          muiTableProps={{
            sx: {
              tableLayout: "fixed",
            },
          }}
          muiTableContainerProps={{
            sx: {
              maxHeight: 700,
              overflow: "auto", // Enable scrolling
              "&::-webkit-scrollbar": {
                width: "10px", // Width of the scrollbar
              },
              "&::-webkit-scrollbar-thumb": {
                background: "#888", // Color of the scrollbar thumb
                borderRadius: "10px", // Rounded corners of the thumb
              },
              "&::-webkit-scrollbar-thumb:hover": {
                background: "#555", // Color of the scrollbar thumb on hover
              },
            },
          }}
          editDisplayMode="cell"
          // enableEditing
          // muiEditTextFieldProps={({ cell }) => ({
          //   onBlur: (event) => {
          //     void handleSaveCell(cell, event.target.value);
          //   },
          // })}
          enableClickToCopy
          enableColumnResizing={false}
          enableColumnActions={false}
          enableColumnFilters={false}
          enablePagination={false}
          enableSorting={false}
          enableBottomToolbar={false}
          enableTopToolbar={false}
        />
      </Box>
    </>
  );
}
