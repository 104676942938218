import { Typography, TypographyProps } from "@mui/material";
import { NumericFormat } from "react-number-format";

import { getUnit } from "../helpers/numberFormatHelpers";

type RNumberLabelProps = {
  value: string | number;
  decimalScale?: number;
  typographyProps?: TypographyProps;
  category: "currency" | "emissions" | "duration" | "percentage" | "quantity" | "outcome";
  customUnit?: string;
};
export default function RNumberLabel({
  value,
  customUnit,
  category,
  decimalScale = 0,
  typographyProps,
}: RNumberLabelProps) {
  // function that returns an object which contains suffix and prefix
  const unit = getUnit(category, customUnit);
  return (
    <Typography {...typographyProps} sx={{ textAlign: "right" }}>
      <NumericFormat
        value={value}
        displayType="text"
        thousandSeparator
        fixedDecimalScale
        decimalScale={decimalScale}
        prefix={unit.prefix}
        suffix={unit.suffix}
      />
    </Typography>
  );
}
